import React from 'react';
import './button.scss';

export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Success = 'success',
  Danger = 'danger',
}

export const Button: React.FC<{
  type: ButtonType
  outline?: boolean
  onClick: () => void
}> = ({ children, type, outline, onClick }) =>
  <button className={`btn btn-${outline ? 'outline-' : ''}${type}`} onClick={onClick}>{children}</button>