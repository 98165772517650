import { Question } from "./types";

export const Questions: Question[] = [
  {
    prompt: 'Which of these do you do first when you wake up?',
    answers: [
      { key: '1', label: 'Make coffee', weightings: { BigBalls: 0, Colourful: 0, Curved: 0.25, Double: 0, Floppy: 0.25, Glass: 0, StrapOn: 0.5 } },
      { key: '2', label: 'Go to the toilet', weightings: { BigBalls: 0, Colourful: 0, Curved: 0, Double: 0, Floppy: 0, Glass: 0.5, StrapOn: 0 } },
      { key: '3', label: 'Check social media', weightings: { BigBalls: 0, Colourful: 0, Curved: 0, Double: 0.5, Floppy: 0, Glass: 0.5, StrapOn: 0 } },
      { key: '4', label: 'Start working', weightings: { BigBalls: 0.5, Colourful: 0, Curved: 0, Double: 0, Floppy: 0, Glass: 0, StrapOn: 0.5 } },
    ],
  },
  {
    prompt: 'How does your bedroom look?',
    answers: [
      { key: '1', label: 'Very messy', weightings: { BigBalls: 0.5, Colourful: 0, Curved: 0, Double: 0, Floppy: 0, Glass: 0, StrapOn: 0 } },
      { key: '2', label: 'Minimalistic', weightings: { BigBalls: 0, Colourful: 0, Curved: 0.25, Double: 0, Floppy: 0, Glass: 0.75, StrapOn: 0 } },
      { key: '3', label: 'Bright and colourful', weightings: { BigBalls: 0, Colourful: 0.75, Curved: 0, Double: 0, Floppy: 0, Glass: 0.25, StrapOn: 0 } },
      { key: '4', label: 'Ready for poontang', weightings: { BigBalls: 0.3, Colourful: 0, Curved: 0, Double: 0, Floppy: 0, Glass: 0, StrapOn: 0.7 } },
    ]
  },
  {
    prompt: 'When do you do your best work?',
    answers: [
      { key: '1', label: 'When I have help from friends', weightings: { BigBalls: 0, Colourful: 0, Curved: 0, Double: 1, Floppy: 0, Glass: 0, StrapOn: 0 } },
      { key: '2', label: 'When I wake up', weightings: { BigBalls: 0, Colourful: 0, Curved: 0, Double: 0, Floppy: 0, Glass: 1, StrapOn: 0 } },
      { key: '3', label: 'When I\'m feeling inspired', weightings: { BigBalls: 0, Colourful: 0.25, Curved: 0.5, Double: 0, Floppy: 0.25, Glass: 0, StrapOn: 0 } },
      { key: '4', label: 'All the time, baby', weightings: { BigBalls: 0.75, Colourful: 0, Curved: 0, Double: 0, Floppy: 0, Glass: 0, StrapOn: 0.25 } },
    ]
  },
  {
    prompt: 'Which superpower do you chose?',
    answers: [
      { key: '1', label: 'Flight', weightings: { BigBalls: 0, Colourful: 0.5, Curved: 0, Double: 0, Floppy: 0.5, Glass: 0, StrapOn: 0 } },
      { key: '2', label: 'Invisibility', weightings: { BigBalls: 0, Colourful: 0.5, Curved: 0, Double: 0, Floppy: 0, Glass: 0.5, StrapOn: 0 } },
      { key: '3', label: 'Hyper-intellect', weightings: { BigBalls: 0, Colourful: 0, Curved: 0.5, Double: 0, Floppy: 0, Glass: 0, StrapOn: 0.5 } },
      { key: '4', label: 'Superhuman strength', weightings: { BigBalls: 0.5, Colourful: 0, Curved: 0, Double: 0.5, Floppy: 0, Glass: 0, StrapOn: 0 } },
    ]
  },
  {
    prompt: 'Which house are you in?',
    answers: [
      { key: '1', label: 'Gryffindor', weightings: { BigBalls: 0, Colourful: 0, Curved: 0, Double: 1, Floppy: 0, Glass: 0, StrapOn: 0 } },
      { key: '2', label: 'Slytherin', weightings: { BigBalls: 0.5, Colourful: 0, Curved: 0, Double: 0, Floppy: 0, Glass: 0, StrapOn: 0.5 } },
      { key: '3', label: 'Hufflepuff', weightings: { BigBalls: 0, Colourful: 0.25, Curved: 0, Double: 0, Floppy: 0.75, Glass: 0, StrapOn: 0 } },
      { key: '4', label: 'Ravenclaw', weightings: { BigBalls: 0, Colourful: 0, Curved: 0.5, Double: 0, Floppy: 0, Glass: 0.5, StrapOn: 0 } },
    ]
  },
  {
    prompt: 'What age do you want to be?',
    answers: [
      { key: '1', label: '0-20', weightings: { BigBalls: 0, Colourful: 0.25, Curved: 0.25, Double: 0, Floppy: 0.5, Glass: 0, StrapOn: 0 } },
      { key: '2', label: '20-40', weightings: { BigBalls: 0, Colourful: 0.25, Curved: 0, Double: 0.25, Floppy: 0, Glass: 0, StrapOn: 0.5 } },
      { key: '3', label: '40-60', weightings: { BigBalls: 0, Colourful: 0, Curved: 0, Double: 0, Floppy: 0, Glass: 0.75, StrapOn: 0.25 } },
      { key: '4', label: '60+', weightings: { BigBalls: 1, Colourful: 0, Curved: 0, Double: 0, Floppy: 0, Glass: 0, StrapOn: 0 } },
    ]
  },
  {
    prompt: 'What do you do most at parties?',
    answers: [
      { key: '1', label: 'Talk to people I don\'t know', weightings: { BigBalls: 0, Colourful: 0, Curved: 0, Double: 0, Floppy: 1, Glass: 0, StrapOn: 0 } },
      { key: '2', label: 'Quietly sit and listen to the music', weightings: { BigBalls: 0, Colourful: 0.25, Curved: 0, Double: 0, Floppy: 0, Glass: 0.75, StrapOn: 0 } },
      { key: '3', label: 'Eat all of their food', weightings: { BigBalls: 1, Colourful: 0, Curved: 0, Double: 0, Floppy: 0, Glass: 0, StrapOn: 0 } },
      { key: '4', label: 'Recommend shows or movies', weightings: { BigBalls: 0, Colourful: 0, Curved: 0.5, Double: 0.25, Floppy: 0, Glass: 0, StrapOn: 0.25 } },
    ]
  },
  {
    prompt: 'Did you expect me to actually build this quiz from scratch?',
    answers: [
      { key: '1', label: 'Definitely', weightings: { BigBalls: 0, Colourful: 0.25, Curved: 0.25, Double: 0, Floppy: 0.25, Glass: 0, StrapOn: 0.5 } },
      { key: '2', label: 'Not at all', weightings: { BigBalls: 0, Colourful: 0, Curved: 0, Double: 1, Floppy: 0, Glass: 0, StrapOn: 0 } },
      { key: '3', label: 'Kinda', weightings: { BigBalls: 0, Colourful: 0, Curved: 0, Double: 0, Floppy: 1, Glass: 0, StrapOn: 0 } },
      { key: '4', label: 'I was cool either way', weightings: { BigBalls: 0, Colourful: 0, Curved: 0, Double: 0, Floppy: 0.5, Glass: 0.5, StrapOn: 0 } },
    ]
  },
  {
    prompt: 'What\'s your favourite drink?',
    answers: [
      { key: '1', label: 'Cocktails', weightings: { BigBalls: 0, Colourful: 1, Curved: 0, Double: 0, Floppy: 0, Glass: 0, StrapOn: 0 } },
      { key: '2', label: 'Beer', weightings: { BigBalls: 1, Colourful: 0, Curved: 0, Double: 0, Floppy: 0, Glass: 0, StrapOn: 0 } },
      { key: '3', label: 'Wine', weightings: { BigBalls: 0, Colourful: 0, Curved: 0, Double: 0, Floppy: 1, Glass: 0, StrapOn: 0 } },
      { key: '4', label: 'Soda', weightings: { BigBalls: 0, Colourful: 0, Curved: 1, Double: 0, Floppy: 0, Glass: 0, StrapOn: 0 } },
    ]
  },
  {
    prompt: 'If you were a dog, how would your bark sound?',
    answers: [
      { key: '1', label: 'Squeaky', weightings: { BigBalls: 0, Colourful: 0.6, Curved: 0, Double: 0.3, Floppy: 0.1, Glass: 0, StrapOn: 0 } },
      { key: '2', label: 'Deep', weightings: { BigBalls: 0.4, Colourful: 0, Curved: 0, Double: 0, Floppy: 0, Glass: 0.3, StrapOn: 0.3 } },
      { key: '3', label: 'Growl', weightings: { BigBalls: 0.35, Colourful: 0, Curved: 0, Double: 0, Floppy: 0, Glass: 0.25, StrapOn: 0.4 } },
      { key: '4', label: 'Whimper', weightings: { BigBalls: 0, Colourful: 0.5, Curved: 0.1, Double: 0.1, Floppy: 0.3, Glass: 0, StrapOn: 0 } },
    ]
  },
  {
    prompt: 'Who should have won Australian Idol in 2003?',
    answers: [
      { key: '1', label: 'Guy Sebastian', weightings: { BigBalls: 0, Colourful: 0, Curved: 0, Double: 0.5, Floppy: 0, Glass: 0.25, StrapOn: 0.25 } },
      { key: '2', label: 'Shannon Noll', weightings: { BigBalls: 0.25, Colourful: 0.5, Curved: 0.25, Double: 0, Floppy: 0, Glass: 0, StrapOn: 0 } },
    ]
  }
];
