import React, { FC } from "react";
import { useContext } from "react";
import { answerSelected, resetSelected, undoSelected } from "../actions";
import { Button, ButtonType } from "../components/button";
import { AppContext } from "../context"
import { QuestionItem } from "./components/item";
import { Result } from "./components/result";
import { Answer } from "./types";

export const QuestionsPage: FC = () => {
  const {
    questions: { todo, completed, scores },
    dispatch
  } = useContext(AppContext);
  const submitAnswer = (answer: Answer) => dispatch(answerSelected(answer.key));
  const goBack = () => dispatch(undoSelected());
  const resetQuiz = () => dispatch(resetSelected());
  const question = todo[todo.length - 1];

  if (!question) {
    return <>
      <Result scores={scores} />
      <Button type={ButtonType.Primary} onClick={resetQuiz}>&#9851; Reset Quiz</Button>
    </>
  }

  return <>
    <QuestionItem {...question} onAnswer={submitAnswer} />
    { completed.length > 0 && <Button type={ButtonType.Primary} outline onClick={goBack}>&#9756; Previous Question</Button> }
  </>;
}
