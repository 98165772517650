import { Reducer } from 'react'
import { Action } from '../actions'
import { Questions } from './questions'
import { Question, Weightings } from './types'

export interface QuestionState {
  todo: Question[]
  completed: Question[]
  scores: Weightings[]
}

export const initialQuestionState = {
  todo: Questions.reverse(),
  completed: [],
  scores: []
}

export const questionReducer: Reducer<QuestionState, Action> = (state, action) => {
  switch(action.type) {
    case 'AnswerSelected':
      return completeQuestion(state, action.key);
    case 'UndoSelected':
      return undoQuestion(state);
    case 'ResetSelected':
      return initialQuestionState;
    default:
      return state;
  }
}

const completeQuestion = (state: QuestionState, answerKey: string): QuestionState => {
  const { todo, completed, scores } = state;
  const questionAnswered = todo[todo.length - 1];
  const givenAnswer = questionAnswered?.answers.find(answer => answerKey === answer.key);
  if (!questionAnswered || !givenAnswer) return state;
  return {
    ...state,
    todo: todo.slice(0, todo.length - 1),
    completed: [ ...completed, questionAnswered ],
    scores: [ ...scores, givenAnswer.weightings ],
  };
}

const undoQuestion = (state: QuestionState): QuestionState => {
  const { completed, scores } = state;
  const questionUndone = completed[completed.length - 1];
  if (!questionUndone) return state;
  return {
    ...state,
    todo: [ ...state.todo, questionUndone ],
    completed: completed.slice(0, completed.length - 1),
    scores: scores.slice(0, scores.length -1),
  };
}