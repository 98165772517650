import React from 'react';

export const Row: React.FC = ({ children }) =>
  <div className='row'>{children}</div>

export const Column: React.FC<{ className: string, md: number, xs: number }> = ({ children, className, md, xs }) => {
  const classes = [];
  if (md) classes.push(`col-md-${md}`);
  if (xs) classes.push(`col-xs-${xs}`);
  if (className) classes.push(className);
  return <div className={classes.join(' ')}>{children}</div>
}