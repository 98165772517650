export enum D {
  BigBalls = 'BigBalls',
  Colourful = 'Colourful',
  Curved = 'Curved',
  Double = 'Double',
  Floppy = 'Floppy',
  Glass = 'Glass',
  StrapOn = 'StrapOn',
}

export type Weightings = { [ option in D ]: number };

export interface Answer {
  key: string
  label: string
  weightings: Weightings
}

export interface Question {
  prompt: string
  answers: Answer[]
}