import { createContext, Dispatch, Reducer, useReducer } from 'react';
import { Action } from './actions';
import { initialQuestionState, questionReducer, QuestionState } from './questions/reducer';

interface AppState {
  questions: QuestionState
}

export const AppContext = createContext<AppState & { dispatch: Dispatch<Action> }>({} as AppState & { dispatch: Dispatch<Action> });

const reducer: Reducer<AppState, Action> = (state, action) => ({
  questions: questionReducer(state.questions, action),
});

export const useAppContext = () => {
  const initialState: AppState = {
    questions: initialQuestionState,
  };
  const [state, dispatch] = useReducer(reducer, initialState);

  return { ...state, dispatch };
};