import React, { FC, useEffect } from 'react';
import { Answer, Question } from "../types";
import { Column, Row } from '../../components/grid';
import './item.scss';

export const QuestionItem: FC<Question &{
  onAnswer: (answer: Answer) => void
}> =
  ({ prompt, answers, onAnswer }) => {
    useEffect(() => window.scrollTo(0, 0), [ prompt ]);

    return <div className='question'>
      <h2>{prompt}</h2>
      <Row>
        {answers.map(answer =>
          <Column md={6} xs={12} className='answer' key={answer.key}>
            <AnswerItem {...answer} onClick={() => onAnswer(answer)} />
          </Column>
        )}
      </Row>
    </div>
  }

const AnswerItem: FC<Answer & { onClick: () => void }> =
  ({ label, onClick }) => (
    <button onClick={onClick}>{label}</button>
  );