import React, { FC } from 'react';
import { D, Weightings } from '../types';
import './result.scss';

export const Result: FC<{
  scores: Weightings[],
}> = ({ scores }) => {
  const result = calculateResult(scores);
  return <div className='result'>
    <h2>
      You are a {getTitle(result)}
      <small>{getSubTitle(result)}</small>
    </h2>
    {getDescription(result)}
    <a target="_blank" rel="noopener noreferrer" href={getAffiliteLink(result)}>Buy this here!</a>
  </div>;
};

const getTitle = (result: D) => {
  switch(result) {
    case D.BigBalls:
      return 'Big-Balled Dildo';
    case D.Colourful:
      return 'Colourful Dildo';
    case D.Curved:
      return 'Curved Dildo';
    case D.Double:
      return 'Double Dildo';
    case D.Floppy:
      return 'Floppy Dildo';
    case D.Glass:
      return 'Glass Dildo';
    case D.StrapOn:
      return 'Strap-On Dildo';
  }
};

const getSubTitle = (result: D) => {
  switch(result) {
    case D.BigBalls:
      return 'Whoa, Nelly!';
    case D.Colourful:
      return 'Oh, egads!';
    case D.Curved:
      return 'Ohoho, nice!';
    case D.Double:
      return 'Aww, how thoughtful of you.';
    case D.Floppy:
      return 'Whoa, quit floppin\' around!';
    case D.Glass:
      return 'Ahh, so graceful.';
    case D.StrapOn:
      return 'Hohoho, I see.';
  }
};

const getDescription = (result: D) => {
  switch(result) {
    case D.BigBalls:
      return <>
        <p>Looks like you've got some big 'ol cojones. You're unapologetically yourself, don't shy-away from a challenge and aren't afraid to get out of your comfort zone.</p>
        <p>Better watch out though, if you're not careful you might get into a situation that not even <i>your</i> balls can handle.</p>
      </>;
    case D.Colourful:
      return <>
        <p>You may as well be Aurora Borealis, localised entirely within your kitchen. You are easily exciteable, bubbly while also very multifaceted.</p>
        <p>Better watch out though, you're going have to deal with the colourblind ones that can't appreciate all of your wonderful shades.</p>
      </>;
    case D.Curved:
      return <>
        <p>Bend it like Beckham, baby. You're a little off-the-wall, but the angles you have to offer are easy to appreciate. Everyone can get behind a pleasant surprise once in a while.</p>
        <p>Better watch out though, if you're not careful you could rub someone in the wrong spot and leave behind a disaster.</p>
      </>;
    case D.Double:
      return <>
        <p>You're like Darth Maul, but with a dildo. You care about others' happiness as much as your own, sometimes even more for the right person. Your empathy is huge, you're the peoples' champion.</p>
        <p>Better watch out though, you better remember to look after yourself too. You can't help others effectively if you're not in mint condition.</p>
      </>;
    case D.Floppy:
      return <>
        <p>What's going on with this thing, it's floppin' everywhere! You're the unpredictable ball of energy that puts some spice into life. You're a great addition to an already tasty dish.</p>
        <p>Better watch out though, misdirecting your bursts of energy could mean slapping the wrong person in the face with a surprise they never asked for.</p>
      </>;
    case D.Glass:
      return <>
        <p>As exquisite as two figure skaters performing the Iron Lotus. You glide smoothly and brilliantly through life, coming out in once piece despite everyone's expectations.</p>
        <p>Better watch out though, taking risks could lead to shattering your terrific glide, not to mention a potential trip to the ER.</p>
      </>;
    case D.StrapOn:
      return <>
        <p>You're here to kick ass and chew bubblegum. And you're all out of gum. You're the pack leader who takes charge of the situation and doesn't take any shit back.</p>
        <p>Better watch out though, it's important to stay on guard even if you think you're invincible. You never know when you're gonna be the one who bends over.</p>
      </>;
  }
};


const calculateResult = (scores: Weightings[]): D => {
  let maxScore = 0;
  let chosen: D | undefined;
  const finalScore = scores.reduce<Weightings>(sumWeightings, { BigBalls: 0, Colourful: 0, Curved: 0, Double: 0, Floppy: 0, Glass: 0, StrapOn: 0 });
  for (const choice in finalScore) {
    const choiceD = choice as D;
    const score = finalScore[choiceD];
    if (score > maxScore) {
      maxScore = score;
      chosen = choiceD;
    }
  }
  if (!chosen) throw new Error('Choice not made');
  return chosen;
};

const getAffiliteLink = (result: D) => {
  switch(result) {
    case D.BigBalls:
      return 'https://amzn.to/3XpDguD';
    case D.Colourful:
      return 'https://amzn.to/3In7euQ';
    case D.Curved:
      return 'https://amzn.to/3idAYQf';
    case D.Double:
      return 'https://amzn.to/3CqtCjj';
    case D.Floppy:
      return 'https://amzn.to/3vGNAT0';
    case D.Glass:
      return 'https://amzn.to/3IvJAwp';
    case D.StrapOn:
      return 'https://amzn.to/3vCZq0m';
  }
};

const sumWeightings = (a: Weightings, b: Weightings): Weightings => ({
  BigBalls: a.BigBalls + b.BigBalls,
  Colourful: a.Colourful + b.Colourful,
  Curved: a.Curved + b.Curved,
  Double: a.Double + b.Double,
  Floppy: a.Floppy + b.Floppy,
  Glass: a.Glass + b.Glass,
  StrapOn: a.StrapOn + b.StrapOn
});