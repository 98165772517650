interface AnswerSelected {
  type: 'AnswerSelected'
  key: string
}

export const answerSelected = (key: string): AnswerSelected => ({
  type: 'AnswerSelected',
  key
});

interface UndoSelected {
  type: 'UndoSelected'
}

export const undoSelected = (): UndoSelected => ({
  type: 'UndoSelected'
})

interface ResetSelected {
  type: 'ResetSelected'
}

export const resetSelected = (): ResetSelected => ({
  type: 'ResetSelected'
})

export type Action = AnswerSelected | UndoSelected | ResetSelected;