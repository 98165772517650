import React, { FC } from 'react';
import { AppContext, useAppContext } from './context';
import { QuestionsPage } from './questions/page';
import './app.scss';

function App() {
  const context = useAppContext();
  return (
    <AppContext.Provider value={context}>
      <AppLayout>
        <QuestionsPage />
      </AppLayout>
    </AppContext.Provider>
  );
}

const AppLayout: FC = ({ children }) =>
  <div className="app-layout">{children}</div>

export default App;
